import React from 'react';
import './Scores.css';
import Cards from '../components/Cards';

const Scores = () => {
    return (
        <div>
            <Cards />
        </div>
        
    )
}

export default Scores;